import _ from 'lodash'
import * as constants from './constants'
import * as selectors from './selectors'
import sdk, { baseUrl } from 'services/vigilanceHubService'

class ReportsActions {
  searchReportsFresh = () => ({
    type: constants.SEARCH_REPORTS_FRESH,
    promise: (dispatch, getState) => {
      const { config } = getState()
      const [organisationId, vigilanceHubSource, reportSources = [], allowNewerVersionsFromDifferentSource, includeSourcelessReports] = _.at(config, [
        'websiteConfig.vigilanceHubOrganisationId',
        'websiteConfig.vigilanceHubSource',
        'websiteConfig.vigilanceHubReportSources',
        'websiteConfig.allowNewerVersionsFromDifferentSource',
        'websiteConfig.includeSourcelessReports'
      ])
      const size = selectors.getSize(getState())
      const extReportId = selectors.getExtReportIdForQuery(getState())
      const sources = [vigilanceHubSource, ...reportSources]
      
      return sdk.reports
        .fetchReports({
          page: 1,
          size,
          organisationId,
          sources,
          allowNewerVersionsFromDifferentSource,
          extReportId,
          includeSourcelessReports
        })
    }
  })

  searchReportsRefresh = () => ({
    type: constants.SEARCH_REPORTS_FRESH,
    promise: (dispatch, getState) => {
      const state = getState()
      const size = selectors.getSize(state)
      const { config } = getState()
      const [organisationId, vigilanceHubSource, reportSources = [], allowNewerVersionsFromDifferentSource, includeSourcelessReports] = _.at(config, [
        'websiteConfig.vigilanceHubOrganisationId',
        'websiteConfig.vigilanceHubSource',
        'websiteConfig.vigilanceHubReportSources',
        'websiteConfig.allowNewerVersionsFromDifferentSource',
        'websiteConfig.includeSourcelessReports'
      ])
      const extReportId = selectors.getExtReportIdForQuery(getState())
      const sources = [vigilanceHubSource, ...reportSources]
      return sdk.reports
        .fetchReports({
          page: 1,
          size,
          organisationId,
          sources,
          allowNewerVersionsFromDifferentSource,
          extReportId,
          includeSourcelessReports
        })
    }
  })

  searchReportsNext = () => ({
    type: constants.SEARCH_REPORTS_NEXT,
    promise: (dispatch, getState) => {
      const size = selectors.getSize(getState())
      const page = selectors.getPage(getState())
      const { config } = getState()
      const [organisationId, vigilanceHubSource, reportSources = [], allowNewerVersionsFromDifferentSource, includeSourcelessReports] = _.at(config, [
        'websiteConfig.vigilanceHubOrganisationId',
        'websiteConfig.vigilanceHubSource',
        'websiteConfig.vigilanceHubReportSources',
        'websiteConfig.allowNewerVersionsFromDifferentSource',
        'websiteConfig.includeSourcelessReports'
      ])
      const extReportId = selectors.getExtReportIdForQuery(getState())
      const sources = [vigilanceHubSource, ...reportSources]
      return sdk.reports
        .fetchReports({
          page: page + 1,
          size,
          organisationId,
          sources,
          allowNewerVersionsFromDifferentSource,
          extReportId,
          includeSourcelessReports
        })
    }
  })

  resetStore = () => ({
    type: constants.RESET_STORE
  })

  changeFilters = ({ filters }) => {
    return {
      type: constants.CHANGE_REPORTS_FILTERS,
      page: 1,
      filters
    }
  }

  changeFilter = ({ filter }) => ({
    type: constants.CHANGE_REPORTS_FILTER,
    filter
  })

  fetchReportDetailsById = ({ id }) => ({
    type: constants.FETCH_REPORT_DETAILS_BY_ID,
    promise: (dispatch, getState) => {
      const { config } = getState()
      const organisationId = _.get(config, 'websiteConfig.vigilanceHubOrganisationId')
      return sdk.reports
        .fetchReportById({
          organisationId,
          id
        })
    }
  })

  fetchReportForId = ({ id, format }) => ({
    type: constants.FETCH_REPORT_FOR_ID,
    promise: (dispatch, getState) => {
      return sdk.bulkDownloads
        .requestBulkDownloadURL({
          payload: [id],
          type: 'report',
          format
        })
        .then(({ url }) => {
          window.location = url
          return Promise.resolve()
        })
    }
  })

  fetchReportXMLForIds = ({ ids }) => ({
    type: constants.FETCH_REPORT_FOR_ID,
    promise: (dispatch, getState) => {
      return sdk.bulkDownloads
        .requestBulkDownloadURL({
          payload: ids,
          type: 'report'
        })
        .then(({ url }) => {
          window.location = url
          return Promise.resolve()
        })
    }
  })

  deleteReportById = ({ id }) => ({
    type: constants.DELETE_REPORT_BY_ID,
    promise: (dispatch, getState) => {
      return sdk.reports
        .deleteReportById({
          id
        })
    }
  })

  assignReport = ({userId, reportId, token, emailAddress}) => ({
    type: constants.ASSIGN_REPORT,
    promise: (dispatch, getState) => {
      return sdk.reports
        .assignReport({userId, reportId, token, emailAddress})
    }
  })

  fetchReportAssignmentToken = ({reportId, token, emailAddress}) => ({
    type: constants.FETCH_REPORT_ASSIGNMENT_TOKEN,
    promise: (dispatch, getState) => {
      return sdk.reports
        .fetchReportAssignmentToken({reportId, token, emailAddress})
    }
  })

  getCasesSummary = () => ({
    type: constants.FETCH_CASE_SUMMARY,
    promise: (dispatch, getState) => {
      const { config } = getState()
      const vigilanceHubSource = _.get(config, 'websiteConfig.vigilanceHubSource')
      const vigilanceHubSourceId = _.get(config, 'websiteConfig.vigilanceHubSourceId')

      return sdk.cases
      .fetchCasesForCurrentUserById({
        mode: 'SUMMARY',
        source: vigilanceHubSource,
        sourceId: vigilanceHubSourceId
      })
    }
  })

  fetchReportAssignmentTokenSSR = ({ reportId, token }) => ({
    type: constants.FETCH_REPORT_ASSIGNMENT_TOKEN_SSR,
    promise: async (dispatch, getState) => {
      const res = await fetch(`${baseUrl}/v1/report/${reportId}/verify/${token}`, { method: 'POST' })
      const json = await res.json()

      if (res.ok) {
        return json
      }

      throw new Error(json.code)
    }
  })
}

export default new ReportsActions()
